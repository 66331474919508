// Anchors
// ========
class ScrollTo {
  constructor(elem) {
    this.elem = elem
    this.target = elem.href.split('#')[1] || "header"
    this.init()
  }

  init() {
    this.elem.addEventListener('click', e => {
      e.preventDefault()
      document.getElementById(this.target).scrollIntoView({ block: "start", inline: "nearest", behavior: 'smooth' })
    })
  }
}

window.addEventListener('anchor', (e) => {
  e.target.scroll = new ScrollTo(e.target)
  e.target.click()
});

[...document.querySelectorAll('a[data-html-anchor][href^="#"]')].map(elem => elem.scroll = new ScrollTo(elem))
